import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';

import { UniqueClientResponse } from './personalDetails';

interface GridWrapperProps {
  show: boolean;
  duplicatedClient: UniqueClientResponse | any;
  children: ReactNode;
}

const DuplicateClientErrorHOC: React.FC<GridWrapperProps> = ({
  show,
  duplicatedClient,
  children
}) => {
  if (!show) {
    return <>{children}</>;
  }

  return (
    <Grid
      container
      border={'1px solid #D43F4C'}
      borderRadius={'8px'}
      padding={'12px 16px 0px 16px'}
    >
      <Grid container gap={'12px'} mt={'12px'} mb={'12px'}>
        <Grid item xs={12}>
          Oops this client's already in your database!
        </Grid>
        <Grid
          container
          sx={{
            padding: '4px 4px 4px 16px',
            borderRadius: '4px',
            background: '#FFF0EB',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'space-between'
          }}
        >
          <Grid
            item
            xs={11}
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#314157',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            <span>{duplicatedClient.firstName + ' ' + duplicatedClient.lastName} </span>
            {duplicatedClient?.program && (
              <span style={{ fontWeight: 'normal', marginLeft: '4px' }}>
                {`  (${duplicatedClient?.program})`}{' '}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <a
              href={`/clients/edit/${duplicatedClient.clientId}/profile`}
              target="_blank"
              style={{ color: '#D43F4C', margin: 'auto' }}
            >
              <span className={'material-icons'}>{`${'open_in_new'}`}</span>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};

export default DuplicateClientErrorHOC;
