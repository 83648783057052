import { ReactNode } from 'react';

import style from './sectionTitle.module.scss';

type Props = {
  children: ReactNode;
};

const SectionTitle = ({ children }: Props) => {
  return <h4 className={style.title}>{children}</h4>;
};

export default SectionTitle;
