import clsx from 'clsx';
import {
  Button,
  ConfirmationModalContent,
  DatePicker,
  Dropdown,
  ErrorContainer,
  LabelComponent,
  LoadingSpinner,
  ModalPopup,
  Stack,
  Tooltip
} from 'components';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useFormik } from 'formik';
import debounce from 'lodash.debounce';
import {
  getLsUserRoleObject,
  removeCurrentNote,
  setCurrentNoteID
} from 'modules/networkTools/localStorageTokens';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  deleteNoteInput,
  getClientNote,
  getClientNotes,
  getUsersImageNotes,
  postCreateNote,
  putUpdateNote
} from 'store/clientProfile/clientProfileActions';
import { ClientProfileContentItem, removeNote } from 'store/clientProfile/clientProfileSlice';
import { clientReachedData, noteTypeData, typesOfSupportData } from 'store/clients/clientsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notifyUserSuccess } from 'utils/notifications';
import * as Yup from 'yup';

import { JoyTextArea } from '../../joyTextArea';
import { LastSaved } from '../noteView/addendumForm/lastSaved';
import style from './noteEdit.module.scss';
dayjs.extend(isSameOrAfter);

type Props = {
  note?: ClientProfileContentItem;
  clientId: string;
  organizationId: number;
  isEditMode?: boolean;
  inputOpen?: boolean;
  noteId?: string;
  context: 'popup' | 'noteSection';
};

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

const timeSpentData = [
  { id: '0', name: '0min' },
  { id: '5', name: '5min' },
  { id: '10', name: '10min' },
  { id: '15', name: '15min' },
  { id: '30', name: '30min' },
  { id: '45 ', name: '45min' },
  { id: '60', name: '1hr' },
  { id: '75', name: '1hr 15min' },
  { id: '90', name: '1hr 30min' },
  { id: '105', name: '1hr 45min' },
  { id: '120', name: '2hr' },
  { id: '150', name: '2hr 30min' },
  { id: '180 ', name: '3hr' },
  { id: '210', name: '3hr 30min' },
  { id: '240', name: '4hr' },
  { id: '270 ', name: '>4hr' }
];

const NoteEdit = ({ note, clientId, organizationId, inputOpen, noteId, context }: Props) => {
  const [isAutosaving, setIsAutosaving] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  const [formDisabled, setFormDisabled] = useState(context === 'popup');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const currentOrganizationID = getLsUserRoleObject()?.currentOrganization?.id;
  const { size } = useAppSelector((state) => state.clientProfile.clientProfileList);
  const isFirstRender = useIsFirstRender();
  const [searchParams] = useSearchParams();
  const noteTypes = searchParams.get('noteTypes');

  const sortValues = (val: string[] | string, sortOrder: any[]): string[] => {
    if (Array.isArray(val)) {
      return sortOrder.filter((item) => val.includes(item.id.toString())).map((item) => item.id);
    } else {
      return [val];
    }
  };

  const debouncedNoteAutoSave = useRef(
    debounce(async (formikValues, id, types) => {
      if (formikValues === null) return;
      setIsAutosaving(true);

      if (id) {
        const res = await dispatch(
          putUpdateNote({
            clientId: clientId,
            id,
            note: formikValues.note,
            draft: true,
            noteType: formikValues.noteType || null,
            clientReached: formikValues.clientReached || null,
            dateOfEncounter: formikValues.dateOfEncounter || null,
            nextContact: formikValues.nextContact || null,
            typeOfSupport: formikValues.typeOfSupport || null,
            timeSpent: formikValues.timeSpent
          })
        );

        if (res.meta.requestStatus === 'fulfilled') {
          setLastSaved(new Date().toISOString());
        }
      } else {
        const res = await dispatch(
          postCreateNote({
            clientId,
            organizationId: currentOrganizationID,
            note: formikValues.note,
            draft: true,
            noteType: formikValues.noteType || null,
            clientReached: formikValues.clientReached || null,
            dateOfEncounter: formikValues.dateOfEncounter || null,
            nextContact: formikValues.nextContact || null,
            typeOfSupport: formikValues.typeOfSupport || null,
            timeSpent: formikValues.timeSpent
          })
        );

        if (res.meta.requestStatus === 'fulfilled') {
          setCurrentNoteID({ currentNoteID: res.payload?.id, clientId });
          setLastSaved(new Date().toISOString());
        }
      }
      setIsAutosaving(false);
      await dispatch(
        getClientNotes({
          clientId,
          organizationId,
          page: 0,
          size,
          types
        })
      ).then((res) => {
        if (res.payload.content.length > 0) {
          //TODO need to optimize
          res.payload.content.map((noteItem: ClientProfileContentItem) => {
            if (noteItem.author.profileImageKey) {
              dispatch(
                getUsersImageNotes({
                  profileImageKey: noteItem.author.profileImageKey,
                  userId: clientId,
                  noteId: noteItem.id.toString(),
                  notesList: true
                })
              );
            }
          });
        }
      });
    }, 1000)
  ).current;

  const formik = useFormik({
    initialValues: {
      note: note?.note || null,
      draft: note?.draft || null,
      noteType: note?.noteType || null,
      clientReached: note?.clientReached || null,
      dateOfEncounter: note?.dateOfEncounter || null,
      nextContact: note?.nextContact || null,
      typeOfSupport: note?.typeOfSupport ? sortValues(note.typeOfSupport, typesOfSupportData) : [],
      id: note?.id || null,
      timeSpent: note?.timeSpent || null
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      note: Yup.string().required('Please enter a note to save.').nullable(),
      clientReached: Yup.string().required('Client reached is required.').nullable(),
      noteType: Yup.string().required('Note type is required.').nullable(),
      dateOfEncounter: Yup.string()
        .nullable()
        .required('Date of Encounter is required.')
        .nullable(),
      nextContact: Yup.string()
        .nullable()
        .test(
          'nextContact',
          'Date of Next Contact must be after Date of Encounter.',
          async (fieldVal, values): Promise<boolean | Yup.ValidationError | any> => {
            const formikValues = values.parent;

            if (fieldVal == null || fieldVal === '') {
              return true;
            }

            if (formik.values.dateOfEncounter == null || formik.values.dateOfEncounter === '') {
              return true;
            }
            //check if end time after start time
            let isStartTimeBeforeEndTime = true;
            const startTime = dayjs(`${formikValues.dateOfEncounter}`);

            const endTime = dayjs(`${formikValues.nextContact}`);

            isStartTimeBeforeEndTime = startTime.isBefore(endTime);

            return isStartTimeBeforeEndTime;
          }
        ),

      typeOfSupport: Yup.array()
        .of(Yup.string())
        .min(1, 'Type of support is required.')
        .required('Type of support is required.'),
      timeSpent: Yup.number().nullable().required('Time Spent is required.')
    }),
    onSubmit: async (values, { resetForm }) => {
      if (noteId) {
        const res = await dispatch(
          putUpdateNote({
            clientId: clientId,
            id: noteId,
            note: values.note ?? '',
            draft: false,
            noteType: values.noteType ?? '',
            clientReached: values.clientReached ?? '',
            dateOfEncounter: values.dateOfEncounter ?? '',
            nextContact: values.nextContact ?? '',
            typeOfSupport: values.typeOfSupport ?? [],
            timeSpent: values.timeSpent ?? 0
          })
        );

        if (res.meta.requestStatus === 'fulfilled') {
          if (context === 'popup') {
            opener?.location?.reload();
          }
          removeCurrentNote();
          setLastSaved(null);
          notifyUserSuccess('Note saved.');
          resetForm({
            values: {
              note: null,
              draft: null,
              noteType: null,
              clientReached: null,
              dateOfEncounter: null,
              nextContact: null,
              typeOfSupport: [],
              id: null,
              timeSpent: null
            }
          });
          dispatch(removeNote());

          if (context === 'popup') {
            await dispatch(
              getClientNote({
                clientId,
                noteId,
                organizationId
              })
            );
          }
          await dispatch(
            getClientNotes({
              clientId,
              organizationId,
              page: 0,
              size,
              types: noteTypes ?? undefined
            })
          ).then((response) => {
            if (response.payload.content.length > 0) {
              //TODO need to optimize
              response.payload.content.map((noteItem: ClientProfileContentItem) => {
                if (noteItem.author.profileImageKey) {
                  dispatch(
                    getUsersImageNotes({
                      profileImageKey: noteItem.author.profileImageKey,
                      userId: clientId,
                      noteId: noteItem.id.toString(),
                      notesList: true
                    })
                  );
                }
              });
            }
          });
        }
      }
    }
  });

  useEffect(() => {
    if (isFirstRender) return;
    if (!Boolean(formik.values.note)) return;
    if (formik.isSubmitting) return;

    debouncedNoteAutoSave(formik.values, noteId, noteTypes);
  }, [formik.values]);

  useEffect(() => {
    if (lastSaved) {
      window.onbeforeunload = function () {
        opener?.location?.reload?.();
      };
    }
  }, [lastSaved]);

  const deleteNote = () => {
    formik.resetForm({
      values: {
        note: null,
        draft: null,
        noteType: null,
        clientReached: null,
        dateOfEncounter: null,
        nextContact: null,
        typeOfSupport: [],
        id: null,
        timeSpent: null
      }
    });
    dispatch(removeNote());
    setShowConfirmationModal(false);

    if (noteId) {
      dispatch(
        deleteNoteInput({
          clientId,
          noteId
        })
      )
        .then(() => removeCurrentNote())
        .then(() => {
          dispatch(
            getClientNotes({
              clientId,
              organizationId,
              page: 0,
              size: 2
            })
          );
          if (context === 'popup') {
            opener.location.reload();
            window.close();
          }
        });
    }
  };

  return (
    <Stack width="100%" mt="16px" display="flex" gap="28px" data-test-id="profile-notes-form">
      <form
        onSubmit={formik.handleSubmit}
        id="noteEdit"
        onReset={() => {
          formik.resetForm({
            values: {
              note: null,
              draft: null,
              noteType: null,
              clientReached: null,
              dateOfEncounter: null,
              nextContact: null,
              typeOfSupport: [],
              id: null,
              timeSpent: null
            }
          });
          removeCurrentNote();
          dispatch(removeNote());
          setLastSaved(null);
        }}
      >
        {inputOpen && (
          <>
            <div className={style.inputRow}>
              <div className={style.halfRow}>
                <LabelComponent mandatory text="Note Type" />
                <Dropdown
                  smallDropdown
                  withGradient
                  label="Select"
                  data={noteTypeData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="noteType"
                  id="noteType"
                  value={formik.values.noteType ?? ''}
                  disabled={formDisabled}
                />
                <ErrorContainer
                  visible={formik.touched.noteType && formik.errors.noteType}
                  errors={formik.errors}
                  name="noteType"
                />
              </div>
              <div className={style.halfRow}>
                <LabelComponent mandatory text="Client Reached" />
                <Dropdown
                  smallDropdown
                  withGradient
                  label="Select"
                  data={clientReachedData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="clientReached"
                  id="clientReached"
                  value={formik.values.clientReached ?? ''}
                  disabled={formDisabled}
                />
                <ErrorContainer
                  visible={formik.touched.clientReached && formik.errors.clientReached}
                  errors={formik.errors}
                  name="clientReached"
                />
              </div>
            </div>
            <div className={style.inputRow}>
              <div className={style.halfRow}>
                <LabelComponent mandatory text="Date of Encounter" />
                <DatePicker
                  withGradient
                  onChange={(date: string) => {
                    formik.setFieldValue('dateOfEncounter', date).then(() => {
                      formik.setFieldTouched('dateOfEncounter', true);
                    });
                  }}
                  onBlur={() => formik.setFieldTouched('dateOfEncounter', true)}
                  value={formik.values.dateOfEncounter}
                  placeholder="MM/DD/YYYY"
                  disabled={formDisabled}
                  maxDate={dayjs()}
                />
                <ErrorContainer
                  visible={formik.touched?.dateOfEncounter && formik.errors?.dateOfEncounter}
                  errors={formik.errors}
                  name="dateOfEncounter"
                />
              </div>
              <div className={style.halfRow}>
                <LabelComponent text="Next Contact" />
                <DatePicker
                  withGradient
                  onChange={(date: string) => {
                    formik.setFieldValue('nextContact', date).then(() => {
                      formik.setFieldTouched('nextContact', true);
                    });
                  }}
                  onBlur={() => formik.setFieldTouched('nextContact', true)}
                  value={formik.values.nextContact}
                  placeholder="MM/DD/YYYY"
                  minDate={
                    formik.values.dateOfEncounter != null && formik.values.dateOfEncounter !== ''
                      ? dayjs(formik.values.dateOfEncounter).add(1, 'day')
                      : undefined
                  }
                  disablePast={false}
                  disabled={formDisabled}
                />
                <ErrorContainer
                  visible={formik.touched?.nextContact && formik.errors?.nextContact}
                  errors={formik.errors}
                  name="nextContact"
                  autoHeight
                />
              </div>
            </div>
            <div className={style.inputRow}>
              <div className={style.halfRow}>
                <LabelComponent mandatory text="Time spent" />
                <Dropdown
                  smallDropdown
                  label="Select"
                  data={timeSpentData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.timeSpent?.toString() ?? ''}
                  withGradient
                  name="timeSpent"
                  disabled={formDisabled}
                />
                <ErrorContainer
                  visible={formik.touched.timeSpent && formik.errors.timeSpent}
                  errors={formik.errors}
                  name="timeSpent"
                />
              </div>
              <div className={style.halfRow}>
                <Stack display="flex" flexDirection="row" alignItems="center" gap="4px">
                  <LabelComponent mandatory text="Type of Support" />
                  <Tooltip
                    placement="right"
                    toolTipText={
                      <Stack
                        width="333px"
                        display="flex"
                        gap="8px"
                        padding="4px"
                        className={style.typeOfSupportInfo}
                      >
                        <p>Here's what the different kinds of support mean:</p>
                        <ul>
                          <li>
                            <span>Social support</span> is being there for someone, e.g. going for a
                            walk or making a care package
                          </li>
                          <li>
                            <span>Coaching</span> is working to help someone achieve their
                            well-being goals, e.g. working out with them at the YMCA
                          </li>
                          <li>
                            <span>Navigation</span> is helping someone get what they need from the
                            health and social system, e.g. going with them to an appointment
                          </li>
                          <li>
                            <span>Advocacy</span> is speaking up with and on behalf of someone, e.g.
                            organizing a community action group
                          </li>
                          <li>
                            <span>Problem solving</span> is taking action to address specific
                            challenges. Remember to take action, don't just hand out a referral!
                          </li>
                        </ul>
                      </Stack>
                    }
                  >
                    <span className={clsx('material-icons', style.infoIcon)}>info</span>
                  </Tooltip>
                </Stack>
                <Dropdown
                  smallDropdown
                  withGradient
                  label="Select"
                  data={typesOfSupportData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="typeOfSupport"
                  id="typeOfSupport"
                  value={formik.values.typeOfSupport}
                  categoryList={[{ id: 'PROBLEM_SOLVING', label: 'Problem solving' }]}
                  categorySelectable
                  multiSelect
                  disabled={formDisabled}
                />
                <ErrorContainer
                  autoHeight
                  name="typeOfSupport"
                  errors={formik.errors}
                  visible={formik.touched.typeOfSupport && Boolean(formik.errors.typeOfSupport)}
                />
              </div>
            </div>
          </>
        )}
        <div className={style.halfRow}>
          <JoyTextArea
            value={formik.values.note || ''}
            id="note"
            name="note"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Type here"
            rows={10}
            disabled={formDisabled}
          />
          <ErrorContainer
            visible={formik.touched?.note && formik.errors?.note}
            errors={formik.errors}
            name="note"
          />
        </div>
        <div className={style.saveOrDiscard}>
          <div className={style.btnWrap}>
            <Button
              text=""
              type="button"
              icon={<span className={'material-icons'}>delete</span>}
              handleOnClick={() => setShowConfirmationModal(true)}
            />
            {context === 'popup' && (
              <Button
                text=""
                type="button"
                icon={<span className={'material-icons'}>edit</span>}
                handleOnClick={() => setFormDisabled(false)}
              />
            )}
          </div>
          <div className={style.autosavedBoxWrap}>
            <div className={style.autosavedBox}>
              <Stack>
                {isAutosaving ? <LoadingSpinner size={30} /> : <LastSaved lastSaved={lastSaved} />}
              </Stack>
            </div>
            <Button
              customStyleClass={style.submit}
              theme="primary"
              text="Final"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting || isAutosaving}
            />
          </div>
        </div>
        <ModalPopup
          open={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
        >
          <ConfirmationModalContent
            confirmButtonText="Yes, delete"
            rejectButtonText="No, return to editing"
            title="Are you sure you want to delete your note?"
            customClassInsideContainer={style.deleteNoteModal}
            text=" "
            handleConfirm={deleteNote}
            handleReject={() => setShowConfirmationModal(false)}
          />
        </ModalPopup>
      </form>
    </Stack>
  );
};

export default NoteEdit;
