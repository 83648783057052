import { PHONE_INPUT_MASK } from 'constants/utils';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker, ErrorContainer, InputField, LabelComponent, Stack } from 'components';
import AccordionComponent from 'components/accordion/accordion';
import { useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import { displayErrorStyling } from 'utils/errorMessages';

import { FormValues } from '../../clientForm';
import { isSectionInvalid } from '../personalDetails/personalDetails';

const Referral = () => {
  const formik = useFormikContext<FormValues>();

  return (
    <AccordionComponent
      accordionTabId="fourth"
      title="Referral"
      showWarning={isSectionInvalid(formik?.errors, [
        'referralClient',
        'referralDate',
        'referredBy',
        'referrerPhone',
        'referrerNotes',
        'referrerEmail'
      ])}
    >
      <Stack gap="24px" data-test-id="profile-referral" mt={'24px'}>
        <Stack>
          <Stack gap="24px" display="flex" flexDirection="row">
            <Stack width="100%">
              <LabelComponent text="Referral Client?" mandatory />
              <ToggleButtonGroup
                sx={{
                  '&.MuiToggleButtonGroup-root': {
                    width: '100%'
                  },
                  '&.MuiToggleButtonGroup-root > button': {
                    marginRight: '4px',
                    width: '100%'
                  },
                  '&.MuiToggleButtonGroup-root button': {
                    border: '1px solid #CBD4E1 !important',
                    borderRadius: '4px !important',
                    marginRight: '4px',
                    color: '#314157',
                    textTransform: 'none'
                  },
                  '&.MuiToggleButtonGroup-root button:hover': {
                    backgroundColor: 'transparent'
                  },
                  '&.MuiToggleButtonGroup-root button[aria-pressed="true"]': {
                    backgroundColor: '#EFF9FB',
                    color: '#096F84',
                    border: '1px solid #5EBBCE !important'
                  }
                }}
                value={formik.values.referralClient}
                exclusive
                onChange={(_, value) => {
                  formik.setFieldValue('referralClient', value);
                }}
                onBlur={() => formik.setFieldTouched('referralClient', true)}
                aria-label="Is referral client?"
              >
                <ToggleButton value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
              <ErrorContainer
                visible={formik.touched.referralClient && formik.errors.referralClient}
                errors={formik.errors}
                name="referralClient"
              />
            </Stack>
            <Stack width="100%">
              <LabelComponent
                text="Referral Date"
                mandatory={Boolean(formik.values.referralClient)}
              />
              <DatePicker
                value={formik.values.referralDate}
                onChange={(value: string) => {
                  //@ts-expect-error fix for incorrect formik behavior
                  formik.setFieldValue('referralDate', value).then(() => {
                    formik.setFieldTouched('referralDate', true);
                  });
                }}
                onBlur={() => formik.setFieldTouched('referralDate', true)}
                disableFuture
                disabled={!formik.values.referralClient}
              />
              <ErrorContainer
                visible={formik.touched.referralDate && formik.errors.referralDate}
                errors={formik.errors}
                name="referralDate"
              />
            </Stack>
          </Stack>
          <Stack gap="24px" display="flex" flexDirection="row">
            <Stack width="100%">
              <LabelComponent text="Referred By" />
              <InputField
                value={formik.values.referredBy ?? ''}
                name="referredBy"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter"
                disabled={!formik.values.referralClient}
              />
              <ErrorContainer
                visible={formik.touched.referredBy && formik.errors.referredBy}
                errors={formik.errors}
                name="referredBy"
              />
            </Stack>
            <Stack width="100%">
              <LabelComponent text="Referring Provider Phone Number" />
              <MaskedInput
                guide={false}
                mask={PHONE_INPUT_MASK}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.referrerPhone ?? ''}
                disabled={!formik.values.referralClient}
                render={(ref, props) => (
                  <InputField
                    classnamesProps={displayErrorStyling(
                      formik.touched.referrerPhone,
                      formik.errors.referrerPhone
                    )}
                    placeholder="(000) 000-0000"
                    name="referrerPhone"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    value={formik.values.referrerPhone}
                    ref={(el) => {
                      if (el) {
                        ref(el);
                      }
                    }}
                    disabled={!formik.values.referralClient}
                  />
                )}
              />
              <ErrorContainer
                visible={formik.touched.referrerPhone && formik.errors.referrerPhone}
                errors={formik.errors}
                name="referrerPhone"
              />
            </Stack>
          </Stack>
          <Stack gap="24px" display="flex" flexDirection="row">
            <Stack width="100%">
              <LabelComponent text="Referring Provider Email" mandatory={false} />
              <InputField
                value={formik.values.referrerEmail ?? ''}
                name="referrerEmail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="email@email.com"
                disabled={!formik.values.referralClient}
              />
              <ErrorContainer
                visible={formik.touched.referrerEmail && formik.errors.referrerEmail}
                errors={formik.errors}
                name="referrerEmail"
              />
            </Stack>
            <Stack width="100%">
              <LabelComponent text="Referral Notes" />
              <InputField
                value={formik.values.referrerNotes ?? ''}
                name="referrerNotes"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter"
                disabled={!formik.values.referralClient}
              />
              <ErrorContainer
                visible={formik.touched.referrerNotes && formik.errors.referrerNotes}
                errors={formik.errors}
                name="referrerNotes"
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AccordionComponent>
  );
};

export default Referral;
