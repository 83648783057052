import { PHONE_INPUT_MASK } from 'constants/utils';

import { Grid } from '@mui/material';
import { ErrorContainer, InputField, LabelComponent, Stack } from 'components';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import { getInsurancesAutocomplete, getLanguagesAutocomplete } from 'store/clients/clientsActions';
import { useAppDispatch } from 'store/hooks';
import { displayErrorStyling } from 'utils/errorMessages';

import { FormValues } from '../../clientForm';

const PhoneNumber = () => {
  const dispatch = useAppDispatch();
  const formik = useFormikContext<FormValues>();

  useEffect(() => {
    dispatch(getLanguagesAutocomplete({ term: '' }));
    dispatch(getInsurancesAutocomplete());
  }, []);

  return (
    <div data-test-id="profile-person-details">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={'24px'}>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Primary Phone Number" mandatory />
            <MaskedInput
              guide={false}
              mask={PHONE_INPUT_MASK}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.primaryPhoneNumber ?? ''}
              render={(ref, props) => (
                <InputField
                  classnamesProps={displayErrorStyling(
                    formik.touched.primaryPhoneNumber,
                    formik.errors.primaryPhoneNumber
                  )}
                  placeholder="(000) 000-0000"
                  name="primaryPhoneNumber"
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={formik.values.primaryPhoneNumber}
                  ref={(el) => {
                    if (el) {
                      ref(el);
                    }
                  }}
                />
              )}
            />
            <ErrorContainer
              visible={formik.touched.primaryPhoneNumber && formik.errors.primaryPhoneNumber}
              errors={formik.errors}
              name="primaryPhoneNumber"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Secondary Phone Number" />
            <MaskedInput
              guide={false}
              mask={PHONE_INPUT_MASK}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.secondaryPhoneNumber ?? ''}
              render={(ref, props) => (
                <InputField
                  classnamesProps={displayErrorStyling(
                    formik.touched.secondaryPhoneNumber,
                    formik.errors.secondaryPhoneNumber
                  )}
                  placeholder="(000) 000-0000"
                  name="secondaryPhoneNumber"
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={formik.values.secondaryPhoneNumber}
                  ref={(el) => {
                    if (el) {
                      ref(el);
                    }
                  }}
                />
              )}
            />
            <ErrorContainer
              visible={formik.touched.secondaryPhoneNumber && formik.errors.secondaryPhoneNumber}
              errors={formik.errors}
              name="secondaryPhoneNumber"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Email" />
            <InputField
              value={formik.values.email ?? ''}
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="email@email.com"
            />
            <ErrorContainer
              visible={formik.touched.email && formik.errors.email}
              errors={formik.errors}
              name="email"
            />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhoneNumber;
