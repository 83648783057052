import { PLACEHOLDER_EMAIL } from 'constants/utils';

import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { Button, InputField, LabelComponent, Logo } from 'components';
import { useFormik } from 'formik';
import { getLsAccessToken } from 'modules/networkTools/localStorageTokens';
import { HelpSection } from 'modules/oauth/helpSection';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postAuth0Profile } from 'store/auth/authActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MSG_FIELD_LESS_100_CHARS } from 'utils/errorMessages';
import { notifyUserError } from 'utils/notifications';
import * as Yup from 'yup';

import style from './loginEmail.module.scss';

const LoginEmail: React.FunctionComponent = () => {
  const userState = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const { loginWithRedirect, isAuthenticated }: Auth0ContextInterface<User> = useAuth0();
  const navigate = useNavigate();

  const isUserAuthenticated = isAuthenticated && getLsAccessToken();

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate('/');
    }
  }, []);

  const getLoginLink = (props: { connectionName: string; emailHint: string }) => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth0/permissions`,
        connection: props.connectionName,
        login_hint: props.emailHint
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      ...userState
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .max(100, MSG_FIELD_LESS_100_CHARS)
        .required('Email is required')
    }),

    onSubmit: async (values) => {
      const res: any = await dispatch(postAuth0Profile({ email: values?.email }));

      if (res.meta.requestStatus === 'fulfilled') {
        const connectionName = res.payload?.idpConnection;
        const emailHint = res.payload?.username;

        getLoginLink({ connectionName, emailHint });
      } else {
        notifyUserError('Incorrect email.');
      }
    }
  });
  return (
    <div
      className={style.loginEmailContainer}
      style={{ display: !isUserAuthenticated ? 'flex' : 'none' }}
    >
      <div className={style.logoContainer}>
        <Logo type="orange" />
      </div>
      <form onSubmit={formik.handleSubmit} data-test-id="login-form">
        <div className={style.inputGroup}>
          <LabelComponent text={'Email Address'} mandatory={true} />
          <InputField
            id="email"
            name="email"
            type="email"
            placeholder={PLACEHOLDER_EMAIL}
            classnamesProps={[
              style.inputGroupField,
              formik.touched.email && formik.errors.email && 'globalErrorBorder',
              formik.touched.email && !formik.errors.email && 'globalValidBorder'
            ]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            autoComplete="email"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={style.fieldError}>{formik.errors.email}</div>
          ) : null}
        </div>
        <div className={style.inputGroup}>
          <Button
            theme="primary"
            text={'Sign in'}
            type={'submit'}
            disabled={!formik.isValid}
            data-test-id="sign-in-btn"
          />
        </div>
      </form>
      <div className={style.helpSection}>
        <HelpSection />
      </div>
    </div>
  );
};

export default LoginEmail;
