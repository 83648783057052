import { ErrorContainer, Stack } from 'components';
import AccordionComponent from 'components/accordion/accordion';
import { TextAreaInputField } from 'components/inputField/textAreaInpuField';
import { useFormikContext } from 'formik';

import { FormValues } from '../../clientForm';
import { isSectionInvalid } from '../personalDetails/personalDetails';
import SectionTitle from '../sectionTitle/sectionTitle';

const SupportSystem = () => {
  const formik = useFormikContext<FormValues>();

  return (
    <AccordionComponent
      accordionTabId="third"
      title="Support System"
      showWarning={isSectionInvalid(formik?.errors, ['supportPeople', 'careTeam'])}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{
          paddingBottom: 2
        }}
      >
        <Stack gap="24px" mt={'24px'} data-test-id="profile-support">
          <SectionTitle>Support People</SectionTitle>
          <Stack height="100px">
            <TextAreaInputField
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.supportPeople ?? ''}
              placeholder="List the people who support the client, e.g., a neighbor or close friend"
              name="supportPeople"
            />
            <ErrorContainer
              visible={formik.errors.supportPeople && formik.touched.supportPeople}
              name="supportPeople"
              errors={formik.errors}
            />
          </Stack>
        </Stack>
        <Stack gap="24px" data-test-id="profile-referral">
          <SectionTitle>Care Team</SectionTitle>
          <Stack height="100px">
            <TextAreaInputField
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.careTeam ?? ''}
              placeholder="List members of the client's health and social care teams"
              name="careTeam"
            />
            <ErrorContainer
              visible={formik.errors.careTeam && formik.touched.careTeam}
              name="careTeam"
              errors={formik.errors}
            />
          </Stack>
        </Stack>
      </Stack>
    </AccordionComponent>
  );
};

export default SupportSystem;
