import { LOGIN_PAGE_URL } from 'constants/utils';

import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import {
  getRefreshTokenFromLSAuth0,
  getUserActiveCookie,
  setAutoLogoutRedirectUrl,
  setUserActiveCookie
} from 'modules/networkTools/localStorageTokens';
import { logout } from 'modules/networkTools/networkTools';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { clearInterval, setInterval } from 'worker-timers';

export const useBeforeExitLogout = () => {
  const { logout: auth0logout }: Auth0ContextInterface<User> = useAuth0();

  const { pathname } = useLocation();
  const logUserOutOnBrowserClose = () => {
    auth0logout({
      logoutParams: {
        returnTo: window.location.origin + LOGIN_PAGE_URL
      }
    });
    logout();
  };

  const isOnExcludedPaths =
    pathname === '/' ||
    pathname.includes('auth0') ||
    pathname.includes('permissions') ||
    pathname.includes('invite') ||
    pathname.includes('auth0Error');

  const checkIfCookieExist = () => {
    if (!isOnExcludedPaths) {
      const cookie = getUserActiveCookie();

      if (!cookie) {
        logUserOutOnBrowserClose();
      }
    }
  };

  useEffect(() => {
    checkIfCookieExist();
    const interval = setInterval(() => {
      checkIfCookieExist();
      if (isOnExcludedPaths) {
        setUserActiveCookie(300);
      } else {
        setUserActiveCookie(60);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [pathname]);
};

export const useAutoLogout = <T extends () => void>(idleTime: number) => {
  const { logout: auth0logout }: Auth0ContextInterface<User> = useAuth0();
  const [searchParams] = useSearchParams();

  const context = searchParams.get('context');
  const handleLogout = () => {
    const lsRefreshToken = getRefreshTokenFromLSAuth0('auth0spajs');

    if (context === 'popup') {
      window.close();
      return;
    }
    localStorage.removeItem('lastPage');
    if (lsRefreshToken) {
      setAutoLogoutRedirectUrl(window.location.pathname);
      auth0logout({
        logoutParams: {
          returnTo: window.location.origin + LOGIN_PAGE_URL
        }
      });
      logout();
    }
  };

  useIdleTimer({
    timeout: idleTime * 60_000,
    onIdle: handleLogout,
    debounce: 0,
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ]
  });
};
