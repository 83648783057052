import { PHONE_INPUT_MASK, US_PHONE_REGEX } from 'constants/utils';

import { Avatar } from '@mui/material';
import { Button, InputField } from 'components';
import { PhoneInputField } from 'components/inputField/phoneInputField';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { conformToMask } from 'react-text-mask';
import { useAppDispatch } from 'store/hooks';
import { putUpdateUserInfo } from 'store/profile/profileActions';
import { User } from 'store/profile/profileSlice';
import { MSG_VALID_PHONE_FORMAT } from 'utils/errorMessages';
import * as Yup from 'yup';

import { notifyUserError } from '../../../utils/notifications';
import style from './profile.module.scss';

interface Props {
  setEdit: () => void;
  profile: User | null;
  profileImage: string;
}

const ProfileForm: FC<Props> = ({ setEdit, profile, profileImage }) => {
  const dispatch = useAppDispatch();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [preview, setPreview] = useState<any>();
  const [loading, setLoading] = useState<any>();

  const onSubmit = (values: any) => {
    setLoading(true);

    try {
      dispatch(
        putUpdateUserInfo({
          ...values,
          profileImageKey: selectedFile || profile?.profileImageKey,
          phoneNumber:
            profile &&
            conformToMask(profile?.phoneNumber?.replace('+1', ''), PHONE_INPUT_MASK)
              .conformedValue === values?.phoneNumber
              ? ''
              : values?.phoneNumber
        })
      ).then(() => {
        setLoading(false);
        setEdit();
        setPreview(undefined);
        setSelectedFile(undefined);
      });
    } catch (e: any) {
      console.log('err: ', e);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...profile,
      phoneNumber: profile
        ? conformToMask(profile.phoneNumber?.replace('+1', ''), PHONE_INPUT_MASK).conformedValue
        : ''
    },

    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .matches(US_PHONE_REGEX, MSG_VALID_PHONE_FORMAT)
        .required('Phone number is required')
    }),
    onSubmit
  });

  useEffect(() => {
    if (!selectedFile) {
      return setPreview(undefined);
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    const file = e?.target?.files[0];

    if (!e.target.files || e.target.files.length === 0) {
      return setSelectedFile(undefined);
    }

    const extension = file?.name?.split('.').pop();

    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    if (allowedExtensions.indexOf(extension) < 0) {
      notifyUserError('Please use jpeg, jpg or png format.');
      return setSelectedFile(undefined);
    }
    if (file?.size >= 3145728) {
      notifyUserError('Max photo size is 3 MB.');
      return setSelectedFile(undefined);
    }

    setSelectedFile(e.target.files[0]);
  };

  if (!profile) return null;

  return (
    <div className={style.profileEdit}>
      <div className={style.profileWrap}>
        <div>
          <Avatar
            className={style.avatar}
            alt={profile.profileImageKey}
            src={preview || profileImage}
          >
            <span className="material-icons-outlined">person</span>
          </Avatar>
          <label htmlFor="file-upload">Edit photo</label>
          <input id="file-upload" type="file" onChange={handleFileChange} multiple />
        </div>
        <div className={style.titleWrap}>
          <div className={style.name}>{profile.name}</div>
          <div className={style.role}>{profile.roles.join(', ')}</div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.organizationName && (
          <div className={style.formInput}>
            <label htmlFor="organizationName">Organization</label>
            <InputField
              id="organizationName"
              name="organizationName"
              type="text"
              disabled
              classnamesProps={style.inputForm}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organizationName}
            />
          </div>
        )}
        <div className={style.formInput}>
          <label htmlFor="email">Email</label>
          <InputField
            id="email"
            name="email"
            type="email"
            disabled
            classnamesProps={style.inputForm}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            autoComplete="email"
          />
        </div>
        <div className={style.formInput}>
          <label htmlFor="phoneNumber">Phone</label>
          <PhoneInputField
            id="phoneNumber"
            name="phoneNumber"
            placeholder="(000) 000-0000"
            classnamesProps={[
              style.inputForm,
              formik.touched.phoneNumber && formik.errors.phoneNumber && 'globalErrorBorder',
              formik.touched.phoneNumber && !formik.errors.phoneNumber && 'globalValidBorder'
            ]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            autoComplete="phone"
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className={style.fieldError}>{formik.errors.phoneNumber}</div>
          ) : null}
        </div>
        <div className={style.buttonsWrap}>
          <Button
            customStyleClass={style.submit}
            type="submit"
            text="Submit"
            disabled={loading || (!selectedFile && (!formik.dirty || !formik.isValid))}
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
