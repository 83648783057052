import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import {
  CitiesAutocompleteResponse,
  getCHW,
  getCitiesAutocomplete,
  getClient,
  getClientHistory,
  getClientProfileImg,
  getClientsList,
  getClientsStatuses,
  getInsurancesAutocomplete,
  getLanguagesAutocomplete,
  getMetrics,
  getPrograms,
  getStatesAutocomplete,
  getZipCodesAutocomplete,
  InsurancesAutocompleteResponse,
  LanguagesAutocompleteResponse,
  postCreateClient,
  postUpdateManagerReview,
  postUpdateSnapshot,
  postUploadProfileImg,
  putChangeStatus,
  putClientsFavourite,
  putEditClient,
  RaceType,
  StatesAutocompleteResponse,
  ZipCodesAutocompleteResponse
} from './clientsActions';

export interface ClientsContentItem {
  id: number;
  prependedId: number;
  name: string;
  dateOfBirth: string;
  referral: boolean;
  status: string;
  chwName: string;
  programName: string;
  favourite: boolean;
  startDate: string | null;
  graduationDate: string | null;
  managerReview: string | null;
  lastContact: string | null;
  mpi: boolean | null;
  roadmaps: number | null;
  dateEligible: string | null;
}

export type StatusId =
  | 'ENROLLED'
  | 'PENDING'
  | 'PRE_OUTREACH'
  | 'TEXT_RESPONSE_PENDING'
  | 'PENDING_CHW'
  | 'MAYBE'
  | 'OPT_OUT'
  | 'NOT_REACHED'
  | 'WITHDRAWN'
  | 'LOST_TO_FOLLOW_UP'
  | 'GRADUATED'
  | 'TERMINATED_FOR_SAFETY'
  | 'DECEASED'
  | 'DECLINED';

type Priority = 'MAIN' | 'SECONDARY';

export const noteTypeData = [
  { id: 'CHART_REVIEW', name: 'Chart Audit' },
  { id: 'END_OF_LIFE', name: 'End of Life' },
  { id: 'ENROLLMENT', name: 'Enrollment' },
  { id: 'ENROLLMENT_CHILD', name: 'Enrollment - Child' },
  { id: 'ENROLLMENT_TEEN', name: 'Enrollment - Teen' },
  { id: 'FOLLOW_UP', name: 'Follow-up' },
  { id: 'GRADUATION', name: 'Graduation' },
  { id: 'HEALTH_GOAL', name: 'Health Goal' },
  { id: 'HOSPITALIZATION', name: 'Hospitalization' },
  { id: 'MEET_THE_PERSON_INTERVIEW', name: 'Meet the Person Interview' },
  { id: 'MEET_THE_PERSON_INTERVIEW_CHILD', name: 'Meet the Person Interview - Child' },
  { id: 'MEET_THE_PERSON_INTERVIEW_TEEN', name: 'Meet the Person Interview - Teen' },
  { id: 'MID_PREGNANCY_FOLLOW_UP', name: 'Mid-Pregnancy Follow-up' },
  { id: 'POST_DELIVERY', name: 'Post-Delivery' },
  { id: 'PROVIDER_APPOINTMENT', name: 'Provider Appointment' },
  { id: 'SAFETY_NOTE', name: 'Safety Note' }
];

export const clientReachedData = [
  { id: 'YES_AT_HOME', name: 'Yes, in person at home/community' },
  { id: 'YES_AT_MEDICAL_SETTING', name: 'Yes, in person at a medical setting' },
  { id: 'YES_NOT_IN_PERSON', name: 'Yes, but not in person' },
  { id: 'NO', name: 'No' }
];
export const typesOfSupportData = [
  { id: 'NONE', name: 'None' },
  { id: 'SOCIAL_SUPPORT', name: 'Social support' },
  { id: 'COACHING', name: 'Coaching' },
  { id: 'NAVIGATION', name: 'Navigation' },
  { id: 'ADVOCACY', name: 'Advocacy' },
  {
    id: 'ACTIVITIES_OF_DAILY_LIVING',
    name: 'Activities of daily living',
    category: 'PROBLEM_SOLVING'
  },
  { id: 'BENEFITS_AND_INSURANCE', name: 'Benefits & insurance', category: 'PROBLEM_SOLVING' },
  { id: 'CAREGIVING', name: 'Caregiving', category: 'PROBLEM_SOLVING' },
  { id: 'CHILDCARE', name: 'Childcare', category: 'PROBLEM_SOLVING' },
  { id: 'CLOTHING_AND_GOODS', name: 'Clothing & goods', category: 'PROBLEM_SOLVING' },
  { id: 'COMMUNITY_GROUPS', name: 'Community groups', category: 'PROBLEM_SOLVING' },
  { id: 'EDUCATION', name: 'Education', category: 'PROBLEM_SOLVING' },
  { id: 'EMPLOYMENT', name: 'Employment', category: 'PROBLEM_SOLVING' },
  { id: 'FINANCIAL_STABILITY', name: 'Financial stability', category: 'PROBLEM_SOLVING' },
  { id: 'FOOD', name: 'Food', category: 'PROBLEM_SOLVING' },
  { id: 'HEALTHCARE', name: 'Healthcare', category: 'PROBLEM_SOLVING' },
  { id: 'HOUSING', name: 'Housing', category: 'PROBLEM_SOLVING' },
  { id: 'INCARCERATION_OR_REENTRY', name: 'Incarceration or reentry', category: 'PROBLEM_SOLVING' },
  { id: 'INTIMATE_PARTNER_SAFETY', name: 'Intimate partner safety', category: 'PROBLEM_SOLVING' },
  { id: 'LEGAL', name: 'Legal', category: 'PROBLEM_SOLVING' },
  { id: 'MENTAL_HEALTH', name: 'Mental health', category: 'PROBLEM_SOLVING' },
  { id: 'PHONE_AND_INTERNET', name: 'Phone & internet', category: 'PROBLEM_SOLVING' },
  { id: 'SUBSTANCE_ABUSE', name: 'Substance abuse', category: 'PROBLEM_SOLVING' },
  { id: 'TRANSPORTATION', name: 'Transportation', category: 'PROBLEM_SOLVING' },
  { id: 'UTILITIES', name: 'Utilities', category: 'PROBLEM_SOLVING' }
];

export type Status = {
  id: StatusId;
  name: string;
  priority: Priority;
  description: string;
  category: string;
};

type Author = {
  id: number;
  name: string;
  profileImageKey: string;
};

export type Client = {
  snapshot: string;
  managerReview: {
    lastReviewDate: string | null;
    authorImage: string | null;
    authorName: string | null;
  };
  id?: string;
  prependedId: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  dob: string;
  clientIdentifier?: string;
  language?: string;
  genderIdentity?: string;
  races?: RaceType[];
  genderSelfDescribed?: string;
  pronouns?: string;
  pronounsSelfDescribed?: string;
  insuranceId?: string;
  insurancePlan?: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber?: string;
  profileImageKey?: string;
  primaryAddress: {
    streetAddress: string;
    streetAddress2?: string;
    city: string;
    state: string;
    zipCode: string;
    building: string;
    floor: string;
    room: string;
  };
  sameAsPrimaryAddress: boolean;
  currentLocation: {
    streetAddress?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    building?: string;
    floor?: string;
    room?: string;
  };
  emergencyContacts: {
    name?: string;
    phoneNumber?: string;
    relationship?: string;
    relationshipOther?: string;
  }[];
  supportPeople: string | null;
  careTeam: string | null;
  programId: string | null;
  chwId: string | null;
  referralClient: boolean;
  referralDate?: string;
  referredBy?: string;
  referrerPhone?: string;
  referrerEmail?: string;
  referrerNotes?: string;
  eligibleStatuses: Status[];
  status: Status;
  safetyFlag?: boolean;
};

export type ClientHistoryItem = {
  status: Status;
  programName: string;
  changedAt: string;
  chwName: string;
  author: Author;
};

export interface StatusesItem {
  id: string;
  name: string;
  category: string;
}

export interface ProgramsItem {
  id: number;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  eligibleStatuses: string[];
  valid: boolean;
}

export interface CHWItem {
  userId: number;
  organizationId: number;
  name: string;
  profileImageKey: string;
  active: boolean;
}

export interface ClientsSliceProps {
  clientProfileAccordionState: {
    clientId: string;
    accordionOpen: {
      first: boolean;
      second: boolean;
      third: boolean;
      fourth: boolean;
      fifth: boolean;
    };
  };
  notes: {
    currentNoteID: any | null;
  };
  bulkClientUpload: {
    bulkClientUploadUUID: string | null;
    bulkClientUploadOrgId: string | null;
    fileName: string | null;
  };
  clientsList: {
    content: ClientsContentItem[];
    page: number;
    size: number;
    totalNumber: number;
    percentageOfTotal: number | null;
  };
  clientsListLoading: boolean;
  statusesList: StatusesItem[];
  metricsList: any[];
  programsList: ProgramsItem[];
  chwList: CHWItem[];
  client: {
    loading: boolean;
    data: Client | null;
  };
  clientHistory: {
    content: ClientHistoryItem[];
    page: number;
    size: number;
    totalNumber: number;
  };
  citiesAutocomplete: {
    loading: boolean;
    data: CitiesAutocompleteResponse;
  };
  statesAutocomplete: {
    loading: boolean;
    data: StatesAutocompleteResponse;
  };
  zipCodesAutocomplete: {
    loading: boolean;
    data: ZipCodesAutocompleteResponse;
  };
  languagesAutocomplete: {
    loading: boolean;
    data: LanguagesAutocompleteResponse;
  };
  insurancesAutocomplete: {
    loading: boolean;
    data: InsurancesAutocompleteResponse;
  };
  changeStatusModal: null | 'changeStatus' | 'confirm';
}
export const defaultClientProfileAccordionState = {
  first: true,
  second: false,
  third: false,
  fourth: false,
  fifth: false
};
const initialState: ClientsSliceProps = {
  clientProfileAccordionState: {
    clientId: '',
    accordionOpen: defaultClientProfileAccordionState
  },
  notes: {
    currentNoteID: null
  },
  bulkClientUpload: {
    bulkClientUploadUUID: null,
    bulkClientUploadOrgId: null,
    fileName: null
  },
  clientsList: {
    content: [],
    page: 0,
    size: 10,
    totalNumber: 0,
    percentageOfTotal: null
  },
  clientsListLoading: true,
  statusesList: [],
  metricsList: [],
  programsList: [],
  chwList: [],
  client: {
    loading: false,
    data: null
  },
  clientHistory: {
    content: [],
    page: 0,
    size: 99999,
    totalNumber: 0
  },
  citiesAutocomplete: {
    loading: false,
    data: []
  },
  statesAutocomplete: {
    loading: false,
    data: []
  },
  zipCodesAutocomplete: {
    loading: false,
    data: []
  },
  languagesAutocomplete: {
    loading: false,
    data: []
  },
  insurancesAutocomplete: {
    loading: false,
    data: []
  },
  changeStatusModal: null
};

export const clientsSlice = createSlice({
  name: 'clientsSlice',
  initialState,
  reducers: {
    resetAccordionState: (state, action) => {
      (state.clientProfileAccordionState.accordionOpen as any) = defaultClientProfileAccordionState;
    },
    setClientListPage: (state, action) => {
      (state.clientsList.page as any) = action.payload;
    },
    updateAccordionState: (state, action) => {
      (state.clientProfileAccordionState.accordionOpen as any)[action.payload.currentAccordionTab] =
        action.payload.currentAccordionValue;
    },
    setBulkClientUploadUUID: (state, action) => {
      state.bulkClientUpload.bulkClientUploadUUID = action.payload.bulkClientUploadUUID;
      state.bulkClientUpload.bulkClientUploadOrgId = action.payload.organizationId;
      state.bulkClientUpload.fileName = action.payload.fileName;
    },
    setNotesCurrentID: (state, action) => {
      state.notes.currentNoteID = action.payload.currentNoteID;
    },
    setNewClientPhoto: (state, action) => {
      if (state.client.data) {
        state.client.data.profileImageKey = action.payload.profileImageKey;
      }
    },
    setChangeStatusModal: (state, action: PayloadAction<'changeStatus' | 'confirm' | null>) => {
      state.changeStatusModal = action.payload;
    },
    resetClientHistory: (state) => {
      state.clientHistory = {
        content: [],
        page: 0,
        size: 99999,
        totalNumber: 0
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsList.pending, (state: any) => {
        state.clientsListLoading = true;
      })
      .addCase(getClientsList.fulfilled, (state: any, { payload }: any) => {
        state.clientsList = payload;
        state.clientsListLoading = false;
      })
      .addCase(getClientsList.rejected, (state: any, { payload }: any) => {
        state.clientsListLoading = false;
        notifyUserError(payload);
      })
      .addCase(getClientsStatuses.fulfilled, (state: any, { payload }: any) => {
        state.statusesList = payload;
      })
      .addCase(getClientsStatuses.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getMetrics.fulfilled, (state: any, { payload }: any) => {
        state.metricsList = payload;
      })
      .addCase(getMetrics.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getPrograms.fulfilled, (state: any, { payload }: any) => {
        state.programsList = payload;
      })
      .addCase(getPrograms.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCHW.fulfilled, (state: any, { payload }: any) => {
        state.chwList = payload.content;
      })
      .addCase(getCHW.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putClientsFavourite.pending, (state: any, { meta }) => {
        // Optimistically update the UI so the change is instant
        const filteredList = state.clientsList.content.map((item: ClientsContentItem) => {
          if (item.id === meta.arg.id) {
            return { ...item, favourite: meta.arg.isFavourite };
          }
          return item;
        });
        state.clientsList = { ...state.clientsList, content: filteredList };
      })
      .addCase(putClientsFavourite.rejected, (state: any, { meta, payload }) => {
        // Revert the optimistic update in case of error
        const filteredList = state.clientsList.content.map((item: ClientsContentItem) => {
          if (item.id === meta.arg.id) {
            return { ...item, favourite: !meta.arg.isFavourite };
          }
          return item;
        });
        state.clientsList = { ...state.clientsList, content: filteredList };
        notifyUserError(payload as any);
      })
      .addCase(postCreateClient.fulfilled, (state, { payload }) => {
        state.client.data = payload;
        notifyUserSuccess('Success! New client created.');
      })
      .addCase(postCreateClient.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putEditClient.fulfilled, (state, { payload, meta }) => {
        state.client.data = { ...payload, clientIdentifier: meta.arg.client.clientIdentifier };
        notifyUserSuccess('Success! Client successfully updated.');
      })
      .addCase(putEditClient.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putChangeStatus.fulfilled, (state, { payload }) => {
        if (state.client.data) {
          state.client.data = {
            ...state.client.data,
            ...payload
          };
        }
        if (payload.status?.id !== state.client.data?.status?.id) {
          notifyUserSuccess('Success! Status successfully updated.');
        } else {
          notifyUserSuccess('Success! Client successfully updated.');
        }
      })
      .addCase(putChangeStatus.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getClient.pending, (state) => {
        state.client.loading = true;
      })
      .addCase(getClient.fulfilled, (state, { payload }: any) => {
        const selfDescribed = payload?.races?.find(
          (race: RaceType) => race?.id === 'SELF_DESCRIBED'
        );
        if (selfDescribed) {
          payload.selfDescribed = selfDescribed?.name;
        }

        state.client = { loading: false, data: payload };
      })
      .addCase(getClient.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getClientHistory.fulfilled, (state, { payload }: any) => {
        state.clientHistory = payload;
      })
      .addCase(getClientHistory.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      // Cities
      .addCase(getCitiesAutocomplete.pending, (state) => {
        state.citiesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getCitiesAutocomplete.fulfilled, (state, action) => {
        state.citiesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getCitiesAutocomplete.rejected, (state, payload: any) => {
        notifyUserError(payload);
      })
      // States
      .addCase(getStatesAutocomplete.pending, (state) => {
        state.statesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getStatesAutocomplete.fulfilled, (state, action) => {
        state.statesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getStatesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // ZIP Codes
      .addCase(getZipCodesAutocomplete.pending, (state) => {
        state.zipCodesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getZipCodesAutocomplete.fulfilled, (state, action) => {
        state.zipCodesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getZipCodesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // Languages
      .addCase(getLanguagesAutocomplete.pending, (state) => {
        state.languagesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getLanguagesAutocomplete.fulfilled, (state, action) => {
        state.languagesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getLanguagesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // Insurances
      .addCase(getInsurancesAutocomplete.pending, (state) => {
        state.insurancesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getInsurancesAutocomplete.fulfilled, (state, action) => {
        state.insurancesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getInsurancesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      .addCase(postUpdateSnapshot.fulfilled, (state, { meta }) => {
        if (state.client.data) {
          state.client.data.snapshot = meta.arg.snapshot;
        }
      })
      .addCase(postUpdateSnapshot.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postUploadProfileImg.fulfilled, (state, { payload }) => {
        notifyUserSuccess('Image uploaded successfully.');
        // if (state.client.data) {
        //   state.client.data.profileImageKey = payload;
        // }
      })
      .addCase(postUploadProfileImg.rejected, (_, { payload }: any) => {
        // notifyUserError(payload);
      })

      .addCase(getClientProfileImg.fulfilled, (state: any, { payload }: any) => {
        // state.client.data.profileImageKey = URL.createObjectURL(new Blob([payload]));
      })
      .addCase(getClientProfileImg.rejected, (_, { payload }: any) => {
        // notifyUserError(payload);
      })
      .addCase(postUpdateManagerReview.fulfilled, (state, { payload }) => {
        if (state.client.data) {
          state.client.data.managerReview = payload;
        }

        notifyUserSuccess('Your changes were saved');
      });
  }
});
export const {
  resetAccordionState,
  setBulkClientUploadUUID,
  setNewClientPhoto,
  setNotesCurrentID,
  setChangeStatusModal,
  resetClientHistory,
  updateAccordionState,
  setClientListPage
} = clientsSlice.actions;
export default clientsSlice.reducer;
