import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from 'modules/networkTools/networkTools';
import { UniqueClientResponse } from 'pages/clients/clientForm/profile/personalDetails/personalDetails';

import { Status } from './clientsSlice';

interface ClientsDTO {
  organizationId: number;
  page?: number;
  size?: number;
  sorts?: { title: string; direction: string }[];
  statuses?: string[] | string;
  q?: string;
}

export const getClientsList = createAsyncThunk(
  'clients/getClientsList',
  async ({ page, size, sorts, statuses, q }: ClientsDTO, { rejectWithValue }) => {
    const QString = q ? `&q=${q}` : '';
    const statusesFiltered = statuses ? `&${statuses}` : '';

    let sortArray: string | string[] = '';
    if (sorts && sorts.length > 0) {
      sortArray = sorts
        .map((item) => item.direction && `&sorts=${item.title}:${item.direction}`)
        .join('');
    }

    try {
      const response = await axiosPrivate.get(
        `clients?page=${page}&size=${size}${QString}${sortArray}${statusesFiltered}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClientsStatuses = createAsyncThunk(
  'clients/getClientsStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/statuses`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMetrics = createAsyncThunk('clients/getMetrics', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosPrivate.get(`clients/metrics`);
    return response.data;
  } catch (error: any | AxiosError) {
    if (error.response && error.response.data.errors) {
      return rejectWithValue(error.response.data.errors);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getPrograms = createAsyncThunk(
  'clients/getPrograms',
  async ({ chwId }: { chwId?: string | null }, { rejectWithValue }) => {
    const searchParams = new URLSearchParams({});

    if (chwId) {
      searchParams.append('chwId', chwId);
    }

    try {
      const response = await axiosPrivate.get(`clients/programs?${searchParams.toString()}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCHW = createAsyncThunk(
  'clients/getCHW',
  async (
    { programId, status }: { programId?: string | null; status?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ sorts: 'name:asc' });

    if (programId) {
      searchParams.append('programIds', programId);
    }
    if (status) {
      searchParams.append('status', status);
    }

    try {
      const response = await axiosPrivate.get(`clients/chws?${searchParams.toString()}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putClientsFavourite = createAsyncThunk(
  'clients/putClientsFavourite',
  async (
    {
      id,

      isFavourite
    }: { id: number; isFavourite: boolean },
    { rejectWithValue }
  ) => {
    const url = `clients/${id}/favourite?isFavourite=${isFavourite}`;
    try {
      await axiosPrivate.put(url, {});
      return { id, isFavourite };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export type RaceType = {
  id: string;
  name: string;
  race: string;
  description: string;
};
export type ClientDTO = {
  firstName: string | null;
  lastName: string | null;
  preferredName?: string | null;
  dob: string | null;
  clientIdentifier?: string | null;
  last4Ssn?: string | null;
  language?: string | null;
  genderIdentity?: string | null;
  races?: RaceType[] | null;
  selfDescribed?: string | null;
  genderSelfDescribed?: string | null;
  pronouns?: string | null;
  pronounsSelfDescribed?: string | null;
  insuranceId?: string | null;
  insurancePlan?: string | null;
  veteranStatus?: string | null;
  primaryPhoneNumber: string | null;
  secondaryPhoneNumber?: string | null;
  email?: string | null;
  profileImageKey?: string | null;
  primaryAddress: {
    streetAddress: string | null;
    streetAddress2?: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    building: string | null;
    floor: string | null;
    room: string | null;
  };
  sameAsPrimaryAddress: boolean;
  currentLocation: {
    streetAddress?: string | null;
    streetAddress2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    building?: string | null;
    floor?: string | null;
    room?: string | null;
  };
  emergencyContacts: {
    name?: string | null;
    phoneNumber?: string | null;
    relationship?: string | null;
    relationshipOther?: string | null;
  }[];
  supportPeople: string | null;
  careTeam: string | null;
  programId?: string | null;
  chwId?: string | null;
  referralClient: boolean | null;
  referralDate?: string | null;
  referredBy?: string | null;
  referrerPhone?: string | null;
  referrerEmail?: string | null;
  referrerNotes?: string | null;
  safetyFlag?: boolean | null;
  duplicatedClient?: UniqueClientResponse | null;
};

export const postCreateClient = createAsyncThunk(
  'clients/posCreateClient',
  async (
    { organizationId, client }: { organizationId: number; client: ClientDTO },
    { rejectWithValue }
  ) => {
    const url = `clients?organizationId=${organizationId}`;
    try {
      const response = await axiosPrivate.post(url, { ...client });

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putEditClient = createAsyncThunk(
  'clients/putEditClient',
  async (
    {
      organizationId,
      clientId,
      client
    }: { organizationId: number; client: ClientDTO; clientId: string },
    { rejectWithValue }
  ) => {
    const url = `clients/${clientId}?organizationId=${organizationId}`;
    try {
      const response = await axiosPrivate.put(url, { ...client });

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type StatusDTO = {
  id?: number | string | null;
  programId?: string | null;
  chwId?: string | null;
  status: Partial<Status>;
};

export const putChangeStatus = createAsyncThunk(
  'clients/putChangeStatus',
  async (
    {
      organizationId,
      clientId,
      statusInfo
    }: { organizationId: number; statusInfo: StatusDTO; clientId: string },
    { rejectWithValue }
  ) => {
    const url = `clients/status/${clientId}?organizationId=${organizationId}`;
    try {
      const response = await axiosPrivate.put(url, { ...statusInfo });

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClient = createAsyncThunk(
  'clients/getClient',
  async (
    { organizationId, clientId }: { organizationId: number; clientId: string },
    { rejectWithValue }
  ) => {
    const url = `clients/${clientId}?organizationId=${organizationId}`;
    try {
      const response = await axiosPrivate.get(url);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClientHistory = createAsyncThunk(
  'clients/getClientHistory',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    const url = `clients/enrollment/history/${clientId}?page=0&size=99999`;
    try {
      const response = await axiosPrivate.get(url);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type City = {
  id: number;
  name: string;
};

export type CitiesAutocompleteResponse = City[];

export const getCitiesAutocomplete = createAsyncThunk(
  'clients/getCitiesAutocomplete',
  async (
    { term, state, zipCode }: { term?: string; state?: string; zipCode?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '10' });
    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (state !== '' && state != null) {
      searchParams.append('state', state);
    }
    if (zipCode !== '' && zipCode != null) {
      searchParams.append('zipCodes', zipCode);
    }

    const url = `clients/city/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as CitiesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type State = {
  id: number;
  name: string;
};

export type StatesAutocompleteResponse = State[];

export const getStatesAutocomplete = createAsyncThunk(
  'clients/getStatesAutocomplete',
  async (
    { term, city, zipCode }: { term?: string; city?: string; zipCode?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '50' });

    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (city !== '' && city != null) {
      searchParams.append('city', city);
    }
    if (zipCode !== '' && zipCode != null) {
      searchParams.append('zipCodes', zipCode);
    }
    const url = `clients/state/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as StatesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type ZipCode = {
  id: number;
  name: string;
};

export type ZipCodesAutocompleteResponse = ZipCode[];

export const getZipCodesAutocomplete = createAsyncThunk(
  'clients/getZipCodesAutocomplete',
  async (
    { term, city, state }: { term?: string; city?: string; state?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '10' });

    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (city !== '' && city != null) {
      searchParams.append('city', city);
    }

    if (state !== '' && state != null) {
      searchParams.append('state', state);
    }
    const url = `clients/zip-codes/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as ZipCodesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type Language = {
  id: number;
  name: string;
};
//TODO here goes delete call ona addendum

export type LanguagesAutocompleteResponse = Language[];

export const getLanguagesAutocomplete = createAsyncThunk(
  'clients/getLanguagesAutocomplete',
  async ({ term }: { term: string }, { rejectWithValue }) => {
    const url = `clients/language/autocomplete?term=${term}&maxResult=10`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as LanguagesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type Insurance = {
  id: number;
  name: string;
};

export type InsurancesAutocompleteResponse = Insurance[];

export const getInsurancesAutocomplete = createAsyncThunk(
  'data/getInsurancesAutocomplete',
  async (_, { rejectWithValue }) => {
    const url = `clients/insurance`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as InsurancesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postUpdateSnapshot = createAsyncThunk(
  'data/putUpdateSnapshot',
  async (
    {
      clientId,
      organizationId,
      snapshot
    }: { clientId: string; organizationId: string; snapshot: string },
    { rejectWithValue }
  ) => {
    const url = `clients/${clientId}/profile/snapshot?organizationId=${organizationId}`;
    try {
      const response = await axiosPrivate.post(url, { snapshot });
      return response.data as InsurancesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postUploadProfileImg = createAsyncThunk(
  'clients/postUploadProfileImg',
  async ({ file, clientId }: { file: File; clientId: string }, { rejectWithValue }) => {
    const url = `clients/${clientId}/profile/image`;
    try {
      const imgUploadConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      const imageUpload = new FormData();
      imageUpload.append('file', file);

      const response = await axiosPrivate.post(url, imageUpload, imgUploadConfig);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClientProfileImg = createAsyncThunk(
  'clients/getClientProfileImg',
  async ({ fileName, clientId }: { fileName: string; clientId: string }, { rejectWithValue }) => {
    const url = `clients/${clientId}/profile/image?filename=${fileName}`;
    try {
      const response = await axiosPrivate.get(url, { responseType: 'blob' });

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloadReport: any = createAsyncThunk(
  'performance/downloadReport',
  async (
    {
      startDate,
      endDate,
      userId,
      program,
      chw,
      programName,
      chwName,
      type = 'Report',
      typeParent
    }: {
      startDate: string;
      endDate: string;
      userId: number;
      program?: number;
      chw?: number;
      programName?: string;
      chwName?: string;
      type: 'Report' | 'DataDefinitions' | 'RawData';
      typeParent: 'Outreach' | 'Activity';
    },
    { rejectWithValue }
  ) => {
    try {
      const programString = program ? `&program=${program}` : '';
      const chwString = chw ? `&chw=${chw}` : '';
      const programNameString = programName ? `&programName=${programName}` : '';
      const chwNameString = chwName ? `&chwName=${chwName}` : '';
      // For type outreach
      if (typeParent === 'Outreach') {
        if (type === 'Report') {
          const response = await axiosPrivate.post(
            `reports/outreach?startDate=${startDate}&endDate=${endDate}&userId=${userId}${programString}${chwString}${programNameString}${chwNameString}`
          );
          return response.data;
        }
        if (type === 'DataDefinitions') {
          const response = await axiosPrivate.get(
            `reports/data-definitions?startDate=${startDate}&endDate=${endDate}&userId=${userId}${programString}${chwString}${programNameString}${chwNameString}`
          );
          return response.data;
        }
        if (type === 'RawData') {
          const response = await axiosPrivate.post(
            `reports/raw-data?startDate=${startDate}&endDate=${endDate}&userId=${userId}${programString}${chwString}${programNameString}${chwNameString}`
          );
          return response.data;
        }
      }
      // For type Activity
      if (typeParent === 'Activity') {
        if (type === 'Report') {
          const response = await axiosPrivate.post(
            `reports/whats-happening?startDate=${startDate}&endDate=${endDate}&userId=${userId}${programString}${chwString}${programNameString}${chwNameString}`
          );
          return response.data;
        }
        if (type === 'DataDefinitions') {
          const response = await axiosPrivate.get(
            `reports/whats-happening-data-definitions?startDate=${startDate}&endDate=${endDate}&userId=${userId}${programString}${chwString}${programNameString}${chwNameString}`
          );
          return response.data;
        }
        if (type === 'RawData') {
          const response = await axiosPrivate.post(
            `reports/whats-happening-raw-data?startDate=${startDate}&endDate=${endDate}&userId=${userId}${programString}${chwString}${programNameString}${chwNameString}`
          );
          return response.data;
        }
      }
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return { error: rejectWithValue(error.response.data.errors) };
      } else {
        return { error: rejectWithValue(error.message) };
      }
    }
  }
);

export const postUpdateManagerReview = createAsyncThunk(
  'clients/putUpdateManagerReview',
  async (
    {
      clientId,
      reviewDate
    }: {
      clientId: string;
      reviewDate: string;
    },
    { rejectWithValue }
  ) => {
    const url = `clients/${clientId}/manager-review`;
    try {
      const response = await axiosPrivate.post(url, {
        reviewDate
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
