import { paths } from '../api/generated-types';

type Endpoints = {
  [key in keyof paths]: keyof paths;
};

const endpoints: Endpoints = {
  '/api/v1/recruiter/profile': '/api/v1/recruiter/profile',
  '/api/v1/recruiter/profile/awards/seen': '/api/v1/recruiter/profile/awards/seen',
  '/api/v1/logout': '/api/v1/logout',
  '/api/v1/coach/assign/recruiter/to/candidate': '/api/v1/coach/assign/recruiter/to/candidate',
  '/api/v1/candidate/status': '/api/v1/candidate/status',
  '/api/v1/candidate/paid': '/api/v1/candidate/paid',
  '/api/v1/recruiter/registration': '/api/v1/recruiter/registration',
  '/api/v1/login': '/api/v1/login',
  '/api/v1/login/refresh': '/api/v1/login/refresh',
  '/api/v1/change-password': '/api/v1/change-password',
  '/api/v1/candidate/{candidateUuid}/registration':
    '/api/v1/candidate/{candidateUuid}/registration',
  '/api/v1/candidate/{candidateUuid}/registration/recruiter/{recruiterUuid}':
    '/api/v1/candidate/{candidateUuid}/registration/recruiter/{recruiterUuid}',
  '/api/v1/recruiter/profile/awards': '/api/v1/recruiter/profile/awards',
  '/api/v1/recruiter/profile/awards/unseen/count': '/api/v1/recruiter/profile/awards/unseen/count',
  '/api/v1/recruiter/dashboard/total-awards': '/api/v1/recruiter/dashboard/total-awards',
  '/api/v1/recruiter/dashboard/reference/link': '/api/v1/recruiter/dashboard/reference/link',
  '/api/v1/coach/recruiter/dashboard/autocomplete':
    '/api/v1/coach/recruiter/dashboard/autocomplete',
  '/api/v1/coach/candidate/dashboard/list': '/api/v1/coach/candidate/dashboard/list',
  '/api/v1/coach/candidate/dashboard/autocomplete':
    '/api/v1/coach/candidate/dashboard/autocomplete',
  '/api/v1/change-password/request/code': '/api/v1/change-password/request/code',
  '/api/v1/candidate/{id}': '/api/v1/candidate/{id}',
  '/api/v1/candidate/{id}/download/video': '/api/v1/candidate/{id}/download/video',
  '/api/v1/candidate/{id}/download/cv': '/api/v1/candidate/{id}/download/cv',
  '/api/v1/candidate/{candidateUuid}/upload-url-post':
    '/api/v1/candidate/{candidateUuid}/upload-url-post',
  '/api/v1/candidate/{candidateUuid}/upload-url-post/recruiter/{recruiterUuid}':
    '/api/v1/candidate/{candidateUuid}/upload-url-post/recruiter/{recruiterUuid}',
  '/api/v1/candidate/{candidateUuid}/cancel': '/api/v1/candidate/{candidateUuid}/cancel'
};

export default endpoints;
