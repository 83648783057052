import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';

import style from './datePicker.module.scss';

type DatePickerProps = {
  disabled?: boolean;
  value: any;
  onBlur?: any;
  onChange: any;
  onFocus?: any;
  disablePast?: boolean;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  classnamesProps?: any;
  disableFuture?: boolean;
  withGradient?: boolean;
  defaultValue?: any;
  notValidateOnClear?: boolean;
};

const BrowserInput = (props: any) => {
  const { inputProps, InputProps, inputRef, ownerState, error, ...other } = props;

  return (
    <div ref={InputProps?.ref}>
      <input
        ref={inputRef}
        {...inputProps}
        {...other}
        focused="true"
        disabled={ownerState?.disabled}
        onFocus={ownerState?.onFocus}
        onBlur={ownerState?.onBlur}
        placeholder={ownerState?.format}
      />
    </div>
  );
};

const DatePicker = (props: DatePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={clsx(
          style.datePicker,
          props.withGradient && style.withGradient,
          props.disabled && style.disabled,
          props.classnamesProps
        )}
      >
        <span onClick={() => setOpen(true)} className="material-icons">
          calendar_today
        </span>
        <MaterialDatePicker
          {...props}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            props.onBlur();
            setOpen(false);
          }}
          disablePast={props.disablePast}
          format={'MM/DD/YYYY'}
          value={props.value ? dayjs(props.value) : null}
          defaultValue={props.defaultValue}
          onChange={(newValue) => {
            if (props.notValidateOnClear) {
              if (newValue === null) {
                return props.onChange(null);
              }
              return props.onChange(dayjs(newValue).format(props?.placeholder || 'YYYY-MM-DD'));
            }
            props.onChange(
              dayjs(newValue).isValid()
                ? dayjs(newValue).format(props?.placeholder || 'YYYY-MM-DD')
                : ''
            );
          }}
          onAccept={props.onBlur ?? undefined}
          slots={{
            textField: BrowserInput
          }}
          disableFuture={props.disableFuture}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DatePicker;
