import { StatusRoadmap } from 'constants/data';

import { SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';
import { Dropdown, LoadingSpinner, Stack } from 'components';
import { FC, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DragStart, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getGoals, getTodos, updateGoalOrder, updateTodoOrder } from 'store/roadmap/roadmapActions';
import {
  AllStatuses,
  GoalsDTO,
  RoadmapStatus,
  TodosDTO,
  updateStatuses,
  updateTodoOrGoalList
} from 'store/roadmap/roadmapSlice';
import { changeDataAndItemAtIndex } from 'utils/changeDataAndItemAtIndex';

import Goal from './goal';
import style from './roadmaps.module.scss';
import ToDo from './todo';

interface Props {
  isTodo: boolean;
}

const Roadmaps: FC<Props> = ({ isTodo }) => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const [selectedDraggableId, setSelectedDraggableId] = useState<string | null>(null);
  const [dragList, setDragList] = useState<(GoalsDTO | TodosDTO)[]>([]);
  const { todosList, goalsList, statusesFilter } = useAppSelector((state) => state.roadmap);
  const [isLoading, setIsLoading] = useState(false);

  const selectData = useMemo(
    () =>
      Object.entries(StatusRoadmap).map((item) => ({
        id: item[0],
        name: item[1]
      })),
    [StatusRoadmap]
  );

  const onDragEnd = (data: DropResult) => {
    setSelectedDraggableId(null);
    if (!data.destination) return;

    const items = Array.from(dragList);
    const [reorderedItem] = items.splice(data.source.index, 1);
    items.splice(data.destination.index, 0, reorderedItem);
    const filtered = changeDataAndItemAtIndex(items, data.destination.index);

    const newData = filtered.filter((a: any) => a.status === RoadmapStatus.ACTIVE);
    if (isTodo) dispatch(updateTodoOrder({ data: newData, clientId }));
    if (!isTodo) dispatch(updateGoalOrder({ data: newData, clientId }));

    dispatch(updateTodoOrGoalList({ data: filtered, isTodo }));
    setDragList(filtered);
  };

  const onDragStart = (data: DragStart) => {
    setSelectedDraggableId(data.draggableId);
  };

  useEffect(() => {
    setDragList(isTodo ? todosList : goalsList);
  }, [todosList, isTodo, goalsList]);

  const getTodosAndGoals = async () => {
    setIsLoading(true);
    await dispatch(getTodos({ statuses: statusesFilter, clientId }));
    await dispatch(getGoals({ statuses: statusesFilter, clientId }));
    setIsLoading(false);
  };

  useEffect(() => {
    getTodosAndGoals();
  }, [isTodo]);

  const statusActive = RoadmapStatus.ACTIVE;
  const filterStatuses = statusesFilter.length ? statusesFilter : AllStatuses;
  const dragActiveTodos =
    filterStatuses.find((a) => a === statusActive) &&
    dragList.filter((a) => a.status === statusActive && (a.id || a.title !== 'Uncategorized'));
  const notActiveTodos =
    filterStatuses.find((a) => a === statusActive) &&
    dragList.filter((a) => a.status !== statusActive || (!a.id && a.title === 'Uncategorized'));

  const dragListFiltered = dragActiveTodos || dragList;

  useEffect(() => {
    return () => {
      dispatch(updateStatuses([RoadmapStatus.ACTIVE]));
    };
  }, []);

  if (!clientId) {
    return null;
  }

  return (
    <div className={style.roadMapsWrap} data-test-id={isTodo ? 'profile-todos' : 'profile-goals'}>
      <Dropdown
        data-test-id="roadmap-statuses-dropdown"
        value={statusesFilter}
        data={selectData}
        className={clsx(style.filter, statusesFilter.length > 0 && style.filterSelected)}
        onChange={(e: SelectChangeEvent<string[] | string>) => {
          const statuses = e.target.value as string[];
          dispatch(updateStatuses(statuses));
          if (isTodo) {
            return dispatch(getTodos({ statuses, clientId }));
          }
          return dispatch(getGoals({ statuses, clientId }));
        }}
        rounded
        label="Status"
        multiSelect
      />

      {isLoading ? (
        <Stack width="100%" display="flex" flexDirection="row" justifyContent="center" mt="24px">
          <LoadingSpinner size={25} />
        </Stack>
      ) : (
        <div className={clsx(style.trackerWrap, selectedDraggableId && style.trackerWrapOpen)}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="droppableRoadmap" type="COLUMN" direction="vertical">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  data-test-id={isTodo ? 'todo-list' : 'goal-list'}
                >
                  {dragListFiltered.map((item, index) => (
                    <Draggable
                      draggableId={item.orderNum.toString()}
                      index={index}
                      key={item.orderNum}
                      isDragDisabled={item.status === StatusRoadmap.ACTIVE}
                    >
                      {(providedChildren) =>
                        isTodo ? (
                          <ToDo
                            clientId={clientId}
                            selectedDraggableId={selectedDraggableId}
                            providedChildren={providedChildren}
                            data={item}
                            isTodo={isTodo}
                          />
                        ) : (
                          <Goal
                            clientId={clientId}
                            selectedDraggableId={selectedDraggableId}
                            providedChildren={providedChildren}
                            data={item}
                          />
                        )
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {notActiveTodos &&
            notActiveTodos.map((item) =>
              isTodo ? (
                <ToDo clientId={clientId} data={item} key={item.orderNum} isTodo={isTodo} />
              ) : (
                <Goal clientId={clientId} key={item.orderNum} data={item} />
              )
            )}
          {!dragList.length && <div className={style.emptyList}>Nothing to see here!</div>}
        </div>
      )}
    </div>
  );
};

export default Roadmaps;
