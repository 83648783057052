import { PHONE_INPUT_MASK } from 'constants/utils';

import { Grid } from '@mui/material';
import { Button, Dropdown, ErrorContainer, InputField, LabelComponent, Stack } from 'components';
import AccordionComponent from 'components/accordion/accordion';
import { FieldArray, getIn, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import { displayErrorStyling } from 'utils/errorMessages';

import { FormValues } from '../../clientForm';
import { isSectionInvalid } from '../personalDetails/personalDetails';
import { PhoneNumber } from '../phoneNumber';
import { PrimaryAddress } from '../primaryAddress';
import SectionTitle from '../sectionTitle/sectionTitle';

const relationships = [
  'Aunt/Uncle',
  'Child',
  'Cousin',
  'Ex-Spouse',
  'Father',
  'Friend',
  'Grandparent',
  'Guardian',
  'Mother',
  'Niece/Nephew',
  'Parent',
  'Sibling',
  'Significant Other',
  'Spouse',
  'Step-child',
  'Step-parent',
  'Other'
];

const ContactInfo = () => {
  const formik = useFormikContext<FormValues>();

  return (
    <AccordionComponent
      accordionTabId="second"
      title="Contact Info"
      showWarning={
        isSectionInvalid(formik.errors?.primaryAddress, [
          'building',
          'city',
          'floor',
          'room',
          'state',
          'streetAddress',
          'streetAddress2',
          'zipCode'
        ]) ||
        isSectionInvalid(formik.errors?.currentLocation, [
          'building',
          'city',
          'floor',
          'room',
          'streetAddress',
          'streetAddress2',
          'zipCode'
        ]) ||
        isSectionInvalid(
          formik.errors?.emergencyContacts,
          ['phoneNumber', 'name', 'relationship', 'relationshipOther'],
          true
        ) ||
        isSectionInvalid(formik.errors, ['primaryPhoneNumber', 'secondaryPhoneNumber', 'email'])
      }
    >
      <PhoneNumber />
      <Stack gap="24px" data-test-id="profile-emergency-contact">
        <SectionTitle>Caregiver or Emergency Contact</SectionTitle>

        <FieldArray
          name="emergencyContacts"
          render={(arrayHelpers) => (
            <div>
              {formik.values.emergencyContacts.map((emergencyContact, index) => (
                <Grid container spacing="24px" key={index}>
                  <Grid item xs>
                    <LabelComponent text="Contact Name"></LabelComponent>
                    <InputField
                      name={`emergencyContacts[${index}].name`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.emergencyContacts[index].name}
                      placeholder="Enter"
                    />
                    <ErrorContainer
                      visible={
                        formik.touched?.emergencyContacts?.[index]?.name &&
                        getIn(formik.errors, `emergencyContacts[${index}].name`)
                      }
                      errors={formik.errors?.emergencyContacts?.[index]}
                      name="name"
                    />
                  </Grid>
                  <Grid item xs>
                    <Stack>
                      <div>
                        <LabelComponent text="Relationship" />
                        <Dropdown
                          withSearch
                          data={relationships.map((relationship) => ({
                            id: relationship,
                            name: relationship
                          }))}
                          value={formik.values.emergencyContacts[index].relationship ?? ''}
                          label="Select"
                          name={`emergencyContacts[${index}].relationship`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <ErrorContainer
                          visible={
                            formik.touched?.emergencyContacts?.[index]?.relationship &&
                            getIn(formik.errors, `emergencyContacts[${index}].relationship`)
                          }
                          errors={formik.errors?.emergencyContacts?.[index]}
                          name="relationship"
                        />
                      </div>
                      {formik.values.emergencyContacts[index].relationship === 'Other' && (
                        <Stack mb="16px">
                          <LabelComponent text="Relationship" />
                          <InputField
                            value={formik.values.emergencyContacts[index].relationshipOther}
                            name={`emergencyContacts[${index}].relationshipOther`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Enter"
                          />
                          <ErrorContainer
                            visible={
                              formik.touched?.emergencyContacts?.[index]?.relationshipOther &&
                              getIn(formik.errors, `emergencyContacts[${index}].relationshipOther`)
                            }
                            errors={formik.errors?.emergencyContacts?.[index]}
                            name="relationshipOther"
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs>
                    <LabelComponent text="Phone Number" />
                    <MaskedInput
                      guide={false}
                      mask={PHONE_INPUT_MASK}
                      onChange={formik.handleChange}
                      name={`emergencyContacts[${index}].phoneNumber`}
                      onBlur={formik.handleBlur}
                      value={formik.values.emergencyContacts[index].phoneNumber ?? ''}
                      render={(ref, props) => (
                        <InputField
                          classnamesProps={displayErrorStyling(
                            formik.touched?.emergencyContacts?.[index]?.phoneNumber,
                            getIn(formik.errors, `emergencyContacts[${index}].phoneNumber`)
                          )}
                          placeholder="(000) 000-0000"
                          name={`emergencyContacts[${index}].phoneNumber`}
                          onChange={props.onChange}
                          onBlur={props.onBlur}
                          value={formik.values.emergencyContacts[index].phoneNumber}
                          ref={(el) => {
                            if (el) {
                              ref(el);
                            }
                          }}
                        />
                      )}
                    />
                    <ErrorContainer
                      visible={
                        formik.touched.emergencyContacts?.[index]?.phoneNumber &&
                        getIn(formik.errors, `emergencyContacts[${index}].phoneNumber`)
                      }
                      errors={formik.errors.emergencyContacts?.[index]}
                      name="phoneNumber"
                    />
                  </Grid>
                </Grid>
              ))}
              <Stack width="160px">
                <Button
                  icon={<span className="material-icons">add</span>}
                  theme="transparent"
                  type="button"
                  text="Add another"
                  handleOnClick={() =>
                    arrayHelpers.push({
                      name: '',
                      phoneNumber: '',
                      relationship: '',
                      relationshipOther: ''
                    })
                  }
                />
              </Stack>
            </div>
          )}
        />
      </Stack>
      <PrimaryAddress context="primaryAddress" />
      <PrimaryAddress context="currentLocation" />
    </AccordionComponent>
  );
};

export default ContactInfo;
