import clsx from 'clsx';
import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { PHONE_INPUT_MASK } from '../../../constants/utils';
import style from './phoneInputField.module.scss';
interface InputFieldProps {
  ref?: any;
  key?: string;
  id?: string;
  type?: 'tel' | 'text';
  name?: string;
  value: any;
  classnamesProps?: any;
  placeholder?: string;
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  visibilityControl?: boolean;
  allowEmails?: boolean;
  autoComplete?: string;
}

const PhoneInputField: React.FunctionComponent<InputFieldProps> = ({
  ref,
  key,
  type,
  id,
  name,
  value,
  placeholder,
  classnamesProps,
  onBlur,
  onChange,
  allowEmails = false,
  autoComplete = 'no-autocomplete'
}) => {
  const isEmail = (char: string) => {
    if (/^[a-z]/i.test(char.charAt(0))) return true;
    //if regex is to slow
    // if (char == '@' || char.toUpperCase() != char.toLowerCase()) return true;
    return false;
  };
  const maskFunction = (rawValue: any) => {
    if (isEmail(rawValue)) {
      //NO MASK on email
      return false;
    }

    return PHONE_INPUT_MASK;
  };
  return (
    <div className={clsx(style.inputContainer)}>
      <MaskedInput
        key={key}
        ref={ref}
        mask={allowEmails ? maskFunction : PHONE_INPUT_MASK}
        id={id}
        value={value}
        guide={true}
        placeholderChar={'\u2000'}
        keepCharPositions={false}
        name={name}
        placeholder={placeholder}
        type={type ? type : 'tel'}
        onChange={onChange}
        onBlur={onBlur}
        className={clsx(style.defaultStyle, classnamesProps)}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default PhoneInputField;
