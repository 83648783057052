import clsx from 'clsx';
import * as React from 'react';

import style from './inputField.module.scss';
import { VisibilityController } from './visibilityController';

interface InputFieldProps {
  key?: string;
  id?: string;
  name?: string;
  value: any;
  classnamesProps?: any;
  placeholder?: string;
  type?: 'text' | 'email' | 'password' | 'tel' | 'number';
  onBlur: any;
  onChange: React.ComponentProps<'input'>['onChange'];
  onFocus?: any;
  visibilityControl?: boolean;
  autoFocus?: boolean;
  isValid?: boolean | string;
  disabled?: boolean;
  pattern?: string;
  min?: string;
  max?: string;
  autoComplete?: string;
  tags?: React.ReactNode;
  rounded?: boolean;
  onEndAdornmentClick?: () => void;
  endAdornment?: React.ReactNode;
  maxLength?: number;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      key,
      pattern,
      id,
      name,
      value = '',
      placeholder,
      type,
      classnamesProps,
      onBlur,
      onChange,
      onFocus,
      visibilityControl = false,
      autoFocus = false,
      isValid = true,
      disabled = false,
      min,
      max,
      autoComplete = 'no-autocomplete', //trick for chrome to disable autocomplete
      tags,
      rounded = false,
      onEndAdornmentClick = () => {},
      endAdornment,
      maxLength,
      onKeyPress
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState(true);
    const handleVisibilityControl = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div
        className={clsx(
          style.inputContainer,
          type === 'password' && style.passwordDotsStyling,
          type === 'password' && !isValid && style.passwordErrorDotsStyling,
          rounded && style.rounded,
          disabled && style.disabled,
          classnamesProps
        )}
      >
        {tags && <div className={style.tags}>{tags}</div>}
        <input
          autoComplete={autoComplete}
          min={min}
          max={max}
          disabled={disabled}
          pattern={pattern}
          ref={ref}
          key={key}
          autoFocus={autoFocus}
          id={id}
          value={value}
          className={clsx(type === 'password' && style.passwordInputStyling)}
          name={name}
          type={showPassword ? type : 'text'}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
        />
        {endAdornment && (
          <div className={clsx(style.visibilityIconContainer)} onClick={onEndAdornmentClick}>
            {endAdornment}
          </div>
        )}
        {visibilityControl && (
          <div
            className={clsx(style.visibilityIconContainer)}
            onClick={() => handleVisibilityControl()}
          >
            <VisibilityController visible={showPassword} />
          </div>
        )}
      </div>
    );
  }
);

export default InputField;
