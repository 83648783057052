import { Tooltip as MaterialTooltip } from '@mui/material';
import { FunctionComponent } from 'react';

interface ModalProps {
  toolTipText: any;
  children: JSX.Element;
  blackTooltip?: boolean;
  greyTooltip?: boolean;
  enterDelay?: number;
  disableInteractive?: boolean;
  followCursor?: boolean;
  placement?: any;
  fullWidth?: boolean;
  disableHoverListener?: boolean;
  styles?: any;
}

const blackStyle = {
  background: '#314157',
  borderRadius: '100px',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '140%',
  color: '#fff',
  padding: '4px 12px',
  fontFamily: `'Nunito', sans-serif`
};

// const greyStyle = {
//   borderRadius: '100px',
//   padding: '4px 12px',
//   fontWeight: '400',
//   fontSize: '12px',
//   lineHeight: '140%',
//   fontFamily: '"ProximaNova", sans-serif',
//   color: '#314157',
//   backgroundColor: '#F1F5F9',
//   marginBottom: '0 !important'
// };

const Tooltip: FunctionComponent<ModalProps> = ({
  toolTipText,
  children,
  blackTooltip,
  greyTooltip,
  disableInteractive = false,
  enterDelay = 100,
  followCursor = false,
  placement = 'bottom',
  fullWidth = false,
  disableHoverListener = false,
  styles
}) => {
  const whiteStyle = {
    maxWidth: '500px',
    background: '#FFFFFF',
    borderTop: placement === 'bottom' ? '3px solid #5EBBCE' : 'undefined',
    borderRight: placement === 'left' ? '3px solid #5EBBCE' : 'undefined',
    borderBottom: placement === 'top' ? '3px solid #5EBBCE' : 'undefined',
    borderLeft: placement === 'right' ? '3px solid #5EBBCE' : 'undefined',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#314157',
    padding: '20px 16px 16px 16px',
    fontFamily: `'Nunito', sans-serif`,
    filter: 'drop-shadow(0px 2px 16px rgba(80, 117, 177, 0.1))',
    boxShadow: '0px 1px 1px rgba(27, 42, 62, 0.05), 0px 3px 8px rgba(27, 42, 62, 0.08)',
    '& .MuiTooltip-arrow': {
      width: '16px',
      height: '16px',
      right: placement === 'left' && '6px !important',
      overflow: 'visible !important',
      '&:before': {
        width: '13px',
        height: '13px',
        background: '#FFFFFF',
        borderWidth: '0px 0px 3px 3px',
        borderStyle: 'solid',
        borderColor: '#5EBBCE',
        borderRadius: '0px 0px 0px 3px',
        transform:
          placement === 'bottom'
            ? 'rotate(135deg)'
            : placement === 'top'
            ? 'rotate(315deg)'
            : placement === 'right'
            ? 'rotate(45deg)'
            : 'rotate(225deg)',
        transformOrigin: 'inherit !important'
      }
    }
  };

  return (
    <MaterialTooltip
      disableInteractive={disableInteractive}
      enterDelay={enterDelay}
      title={toolTipText}
      arrow={!(blackTooltip || greyTooltip)}
      placement={placement || 'bottom'}
      enterTouchDelay={0}
      disableHoverListener={disableHoverListener}
      componentsProps={{
        tooltip: {
          sx: (blackTooltip && { ...blackStyle, ...styles }) || whiteStyle
        }
      }}
      followCursor={followCursor}
    >
      <div style={{ width: fullWidth ? '100%' : 'auto' }}>{children}</div>
    </MaterialTooltip>
  );
};

export default Tooltip;
